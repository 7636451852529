import React, { useState, useEffect } from 'react';

// components
import Header from "../components/header"
import Banner from "../components/banner"
import Footer from "../components/footer"
import SEO from "../components/seo"

import { Container, Row, Col, Button, Modal } from 'react-bootstrap'

import JobListingTable from "../components/job-listing-table"
import FeaturedPositions from "../components/featured-positions-box"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons'

// styles

import * as styles from '../styles/current-openings.module.scss'

// data
import { useCurrentOpenings } from "../hooks/current-openings"
import { usePage } from "../hooks/current-openings-page"
import { useFeaturedPositions } from "../hooks/featured-positions"

import grayLinesBG from '../images/background_stack.jpg'

// markup

const IndexPage = () => {
  const post = usePage();
  const dataFeaturedPositions = useFeaturedPositions();

  const currentOpenings = useCurrentOpenings();
  const [filteredOpenings, setFilteredOpenings] = useState(currentOpenings);

  let filterOptions = new Array;
  filterOptions['positionType'] = new Array;
  filterOptions['positionMarket'] = new Array;
  filterOptions['state'] = new Array;

  let includedValues = new Array;
  includedValues['positionType'] = new Array;
  includedValues['positionMarket'] = new Array;
  includedValues['state'] = new Array;

  currentOpenings.map(node => {


    if( !includedValues['positionType'].includes(node.acfJobListing.positionType.id) ) {
      filterOptions['positionType'].push([ node.acfJobListing.positionType.id, node.acfJobListing.positionType.name ]);
      includedValues['positionType'].push(node.acfJobListing.positionType.id);
    }
    if( !includedValues['positionMarket'].includes(node.acfJobListing.positionMarket.id) ) {
      filterOptions['positionMarket'].push([ node.acfJobListing.positionMarket.id, node.acfJobListing.positionMarket.name ]);
      includedValues['positionMarket'].push(node.acfJobListing.positionMarket.id);
    }
    if( !includedValues['state'].includes(node.acfJobListing.state) ) {
      filterOptions['state'].push([ node.acfJobListing.state, node.acfJobListing.state ]);
      includedValues['state'].push(node.acfJobListing.state);
    }
  });

  const [selectedFilter, setSelectedFilter] = useState([['',''],['','']]);
  const [selectedSort, setSelectedSort] = useState(['','']);

  const resetFilters = () => {
    setSelectedFilter(['',''])
    setFilteredOpenings(currentOpenings)
    document.getElementById('keywords').value = '';
  }

  const filterJobOpenings = (id, value, cumulativeData=false) => {
    let filteredCOpenings = []
    let openingsToFilter = currentOpenings

    if(cumulativeData != false){
      openingsToFilter = cumulativeData
    }

    openingsToFilter.map(node => {
      let currentListingID = '';
      if(id == 'state') {
        currentListingID = node.acfJobListing['state'];
      } else {
        currentListingID = node.acfJobListing[id].id
      }
      if (
        currentListingID == value ||
        value == ""
        ) {
        filteredCOpenings.push(node);
      }
    });
    setFilteredOpenings(filteredCOpenings);

    //updateSelectedFilter([id, value])

    return(filteredCOpenings)
  }

  const updateSelectedFilter = (selectFilter, cumulativeData=false) => {
    let filterList = new Array
    if(cumulativeData != false) {
      filterList.push(cumulativeData)
    } else {
      filterList.push(['',''])
    }
    filterList.push(selectFilter)

    setSelectedFilter(filterList)
  }

  const FilterSelector = (props) => {

    let options = new Array;
    let handleChange = (e) => {
      filterJobOpenings(e.currentTarget.id, e.currentTarget.value)
      updateSelectedFilter([e.currentTarget.id, e.currentTarget.value])
    }


    let selectFilterOptions = props.filterOptions.sort((a, b) => (a[1] > b[1]) ? 1 : -1);
    //console.log('sort');
    //console.log(selectFilterOptions);

    if(props.filterField == 'positionType') {
      selectFilterOptions.unshift(['','Position Type']);
    } else if(props.filterField == 'positionMarket') {
      selectFilterOptions.unshift(['','City']);
    } else if(props.filterField == 'state') {
      selectFilterOptions.unshift(['','State']);
    }
    //console.log('selectedFilter')
    //console.log(selectedFilter)
    return (
      <div className={styles.filter}>
        <select id={ props.filterField} className="form-control" onChange={handleChange}>
          {selectFilterOptions.map(node => {
               return(
                 <option value={ node[0] } selected={((selectedFilter[0][0] == props.filterField) && (selectedFilter[0][1] == node[0])||(selectedFilter[1][0] == props.filterField) && (selectedFilter[1][1] == node[0]))}>{ node[1] }</option>
               )
          })}
        </select>
      </div>
    )
  }

  const handleSortMenuChange = (e) => {
    //console.log('sortFilteredOpenings');
    let field = e.currentTarget.getAttribute('data-field');
    let direction = e.currentTarget.getAttribute('data-direction');
    if(direction == 'up') {
      e.currentTarget.setAttribute('data-direction','down');
    } else {
      e.currentTarget.setAttribute('data-direction','up');
    }


    var matches = document.getElementsByClassName('btn-direction');

    for (var i = 0; i < matches.length; i++) {
      matches[i].classList.remove('direction-up');
      matches[i].classList.remove('direction-down');
    }
    e.currentTarget.classList.add('direction-' + direction);
    sortFilteredOpenings(field, direction);
  }

  const sortFilteredOpenings = (field, direction) => {
    //console.log('sortFilteredOpenings');
    //let field = e.currentTarget.getAttribute('data-field');
    //let direction = e.currentTarget.getAttribute('data-direction');
    let sortOpenings = filteredOpenings;
    //console.log(field);
    //console.log(direction);

    if ( field == 'title' ) {
      if ( direction == 'down') {
        sortOpenings.sort((a, b) => (a.title < b.title) ? 1 : -1);
      } else {
        sortOpenings.sort((a, b) => (a.title > b.title) ? 1 : -1);
      }
    } else if ( field == 'positionType' ) {

      if ( direction == 'down') {

        sortOpenings.sort((a, b) => (a.acfJobListing.positionType.name < b.acfJobListing.positionType.name) ? 1 : -1);
      } else {
        sortOpenings.sort((a, b) => (a.acfJobListing.positionType.name > b.acfJobListing.positionType.name) ? 1 : -1);
      }
    } else if ( field == 'positionMarket' ) {
      if ( direction == 'down') {
        sortOpenings.sort((a, b) => (a.acfJobListing.positionMarket.name < b.acfJobListing.positionMarket.name) ? 1 : -1);
      } else {
        sortOpenings.sort((a, b) => (a.acfJobListing.positionMarket.name > b.acfJobListing.positionMarket.name) ? 1 : -1);
      }
    }
    /*
    [...document.querySelectorAll('.btn-direction')].forEach(function(element) {
      element.classList.remove('direction-up','direction-down');
    });
    */


    setSelectedSort([field, direction]);
    setFilteredOpenings(sortOpenings);

  }

  const keywordFilter = (e) => {
    let keyword = e.target.elements.keywords.value;

    e.preventDefault();
    resetFilters();
    var keywordResults = new Array;
    currentOpenings.map(node => {
     //console.log(node);
      if(
        node.title.toLowerCase().includes(keyword.toLowerCase()) ||
        node.content.toLowerCase().includes(keyword.toLowerCase()) ||
        node.acfJobListing.positionType.name.toLowerCase().includes(keyword.toLowerCase())  ||
        node.acfJobListing.positionMarket.name.toLowerCase().includes(keyword.toLowerCase()) ||
        node.acfJobListing.state.toLowerCase().includes(keyword.toLowerCase())
      ) {
        keywordResults.push(node)
      }
    });
    setFilteredOpenings(keywordResults);
    document.getElementById('keywords').value = keyword;
  }


  // default sort by market
  if(selectedSort[0] == '') {
    setSelectedSort(['positionMarket', 'up']);
    sortFilteredOpenings('positionMarket', 'up');
  }




  useEffect(() => {
    //console.log(window);
    // filter from query strings
    const urlParams = new URLSearchParams(window.location.search);
    if(selectedFilter[0][0] == '' && selectedFilter[1][0] == '') {
      //postionType=dGVybTozOQ==&positionMarket=dGVybToxMDU=

      if(urlParams.get('positionMarket') != null && urlParams.get('positionType') != null) {
        ;
        filterJobOpenings(
          'positionType',
          urlParams.get('positionType'),
          filterJobOpenings(
            'positionMarket',
            urlParams.get('positionMarket')
          )
        );
        updateSelectedFilter(['positionType', urlParams.get('positionType')], ['positionMarket', urlParams.get('positionMarket')])

      } else if(urlParams.get('positionType') != null) {
        filterJobOpenings('positionType',urlParams.get('positionType'));
        updateSelectedFilter(['positionType', urlParams.get('positionType')])
      } else if(urlParams.get('positionMarket') != null) {
        filterJobOpenings('positionMarket',urlParams.get('positionMarket'));
        updateSelectedFilter(['positionMarket', urlParams.get('positionMarket')])
      }
    }
  });
  return(
    <div >
        <main>
          <SEO
            title={post.title}
            description={post.acfSEO.metaDescription}
            image={ (post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src != undefined ) ? post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src : null }
          />
          <Header />
          <Banner
            title={post.acfPageHeader.headerTitle}
            subtitle={post.acfPageHeader.headerSubtitle}
            subtitleFirst="true"
            backgroundImage={post.acfPageHeader.bannerBackgroundImage}
            backgroundVideoUrl={post.acfPageHeader.backgroundVideoUrl}
          >
            <div>{post.acfPageHeader.headerBodyText}</div>
          </Banner>
          <div style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
            <div className={styles.containerFeaturedPositions + " mb-0 p-0"} style={{ position:'relative',
  zIndex: '100', marginTop:'-25vh' }}>
              <FeaturedPositions
                title={post.acfFeaturedPositions.featuredPositionsTitle}
                subtitle={post.acfFeaturedPositions.featuredPositionsSubtitle}
                navCtaButton={post.acfFeaturedPositions.featuredPositionsNavCtaButton}
                slideSubtitle={post.acfFeaturedPositions.featuredPositionsSlideSubtitle}
                ctaButton={post.acfFeaturedPositions.featuredPositionsCtaButton}
                featuredPositions={dataFeaturedPositions}
              />
            </div>
            <div>
              <Container>
                <Row className="mx-3 mx-lg-0" style={{backgroundColor: '#1a302f'}}>
                  <Col lg={3} className="py-3">
                    <form onSubmit={keywordFilter} className={styles.keywordSearchForm}>
                        <input type="text" id="keywords" name="keywords" className={`form-control ${styles.keywordSearch}`} placeholder="Keyword Search" />
                        <button type="submit" className={`btn ${styles.keywordSearchBtn}`} on={keywordFilter} >
                        <FontAwesomeIcon icon={faSearch} />
                        </button>  
                      </form>
                  </Col>
                  <Col lg={9} className={`d-none d-lg-flex py-3`}>
                    <Row className="w-100">
                      <Col lg={4} >
                        <Button variant="link" onClick={handleSortMenuChange} data-direction="up" data-field="title" className="btn-direction pl-0 text-white text-uppercase text-decoration-none text-center" style={{marginLeft: '2.5rem'}}>
                          Title
                          <span className="up pl-2"><FontAwesomeIcon icon={faChevronUp} /></span>
                          <span className="down pl-2"><FontAwesomeIcon icon={faChevronDown} /></span>
                        </Button>
                      </Col>
                      <Col lg={3} style={{overflow: 'visible', whiteSpace: 'nowrap'}}>
                        <Button variant="link" onClick={handleSortMenuChange} data-direction="up" data-field="positionType" className="btn-direction pl-0 text-white text-uppercase text-decoration-none " style={{marginLeft: '1rem'}}>
                          Position Type
                          <span className="up pl-2"><FontAwesomeIcon icon={faChevronUp} /></span>
                          <span className="down pl-2"><FontAwesomeIcon icon={faChevronDown} /></span>
                        </Button>
                      </Col>
                      <Col lg={5} >
                        <Button variant="link" onClick={handleSortMenuChange} data-direction="up" data-field="positionMarket" className="pl-0 text-white text-uppercase btn-direction text-decoration-none" >
                          Location
                          <span className="up pl-2"><FontAwesomeIcon icon={faChevronUp} /></span>
                          <span className="down pl-2"><FontAwesomeIcon icon={faChevronDown} /></span>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="bg-white mx-3 mx-lg-0">
                  <Col lg={3} className="pl-4 text-center text-lg-left pb-4">
                    <FilterSelector filterField="positionType" filterOptions={filterOptions['positionType']} />
                    <FilterSelector filterField="state" filterOptions={filterOptions['state']} />
                    <FilterSelector filterField="positionMarket" filterOptions={filterOptions['positionMarket']} />
                    <Button onClick={resetFilters} style={{borderRadius: "20px", width: "100%"}}>Reset Filters</Button>
                  </Col>
                  <Col lg={9}>
                    <JobListingTable jobListings={filteredOpenings} selectedFilter={selectedFilter} selectedSort={selectedSort} />
                  </Col>
                </Row>

              </Container>
            </div>
          </div>
          <Footer />
        </main>
    </div>);
}




export default IndexPage
