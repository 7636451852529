import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const usePage = () => {
  const { allWpPage } = useStaticQuery(
    graphql`
      query {
        allWpPage(filter: {uri: {eq: "/current-openings/"}}) {
          nodes {
            id
            title
            uri
            acfPageHeader {
              headerBodyText
              headerTitle
              headerSubtitle
              videoBoxBodyText
              videoBoxSubtitle
              videoBoxTitle
              videoBoxVideoUrl
              videoBoxImage{
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              backgroundVideoUrl
              bannerBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            acfFeaturedPositions {
              featuredPositionsSubtitle
              featuredPositionsTitle
              featuredPositionsNavCtaButton
              featuredPositionsSlideSubtitle
              featuredPositionsCtaButton
            }
            acfSEO {
              metaDescription
              title
            }
          }
        }
       }
    `
  )
  return allWpPage.nodes[0];
}
