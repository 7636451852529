import React from 'react'
import { Button, Table } from 'react-bootstrap'


export default (props) => {

  if((props.jobListings != undefined) && (props.jobListings.length != 0)) {
   //console.log(props)
    return(
      <div className="text-primary text-left pr-md-5 pl-md-5 pt-3">
        <Table>
          
          { (props.showHeading == "true") ? <thead>
              <tr className="row d-none d-md-flex pb-0">
                <th className="pb-4 pt-0 text-left col-4 border-0 align-middle text-uppercase">Title</th>
                <th className="pb-4 pt-0 text-left col-3 border-0 align-middle text-uppercase">Position Type</th>
                <th className="pb-4 pt-0 text-left col-3 border-0 align-middle text-uppercase">Location</th>
              </tr> 
            </thead>: <div></div>  }
          
          <tbody>
          
          {props.jobListings.map(node => {
            return(
              <tr
                dataPositionType={ node.acfJobListing.positionType.id }
                dataPositionMarket={ node.acfJobListing.positionMarket.id }
                dataState={ node.acfJobListing.state }
                className="row"
                >
                <td className="pb-0 pt-0 pt-md-2 text-center text-md-left col-md-4 border-0 align-middle">
                  <strong>{ node.title }</strong>
                </td>
                <td className="pb-0 pt-0 pt-md-2 text-center text-md-left col-md-3 border-0 align-middle">{ node.acfJobListing.positionType.name }</td>
                <td className="pb-0 pt-0 pt-md-2 text-center text-md-left col-md-2 border-0 align-middle" >{ node.acfJobListing.positionMarket.name }</td>
                <td className="py-0 col-md-3 border-0 text-center pb-5 pb-m-0 align-middle">
                  <Button className="w-100" href={node.uri.replace('_','-')} style={{borderRadius:'20px', minWidth: '140px', maxWidth: '200px'}}>Apply Now</Button>
                </td>
              </tr>
            )
          })}
          </tbody>
        </Table>
      </div>
    )
  } else {
    return(
      <Table >
        <tbody>
          <tr>
            <td >
              No matching openings.
            </td>
          </tr>
        </tbody>
      </Table>

    )
  }

}
